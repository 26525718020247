<template>
  <VExpansionPanel>
    <VExpansionPanelHeader class="title">
      <div class="d-flex align-center">
        <div class="title">{{ label }}</div>
        <div class="flex-grow-1 pl-4">
          <VTooltip v-if="commentsInCurrentSectionCount['errors']" right>
            <template v-slot:activator="{ on, attrs }">
              <VChip class="mr-2" v-bind="attrs" v-on="on" color="error">{{commentsInCurrentSectionCount['errors']}}</VChip>
            </template>
            <span>Замечания</span>
          </VTooltip>
          <VTooltip v-if="commentsInCurrentSectionCount['warnings']" right>
            <template v-slot:activator="{ on, attrs }">
              <VChip class="mr-2" v-bind="attrs" v-on="on" color="primary">{{commentsInCurrentSectionCount['warnings']}}</VChip>
            </template>
            <span>Исправления</span>
          </VTooltip>
        </div>
        <div v-if="isReviewer && waitDecision && !!questionsInSectionWithoutComments.length && !pending" class="ml-2">
          <VBtn depressed small color="light" @click.stop="dialog = true">Общий комментарий</VBtn>
        </div>
        <div v-if="showFalseValuesButton" class="ml-2">
          <VTooltip top>
            <template v-slot:activator="{ on, attrs }">
              <VBtn depressed small color="light" v-bind="attrs" v-on="on" @click.stop="setFalseValuesInSection">Компетенция отсутствует</VBtn>
            </template>
            <span>Нажмите кнопку, если компетенция из этого раздела к вам не применима</span>
          </VTooltip>
        </div>
        <div v-if="mark" class="px-4">
          <VTooltip left>
            <template v-slot:activator="{ on, attrs }">
              <VChip v-bind="attrs" v-on="on" color="info">{{mark}}%</VChip>
            </template>
            <span>Оценка</span>
          </VTooltip>
        </div>
      </div>
    </VExpansionPanelHeader>
    <VExpansionPanelContent>
      <div v-if="hint" :class="$style.hint" v-html="hint" />
      <VExpansionPanels v-if="Object.keys(filtered(sections)).length" flat multiple>
        <CounterpartyDetailSection
            v-for="(section, sectionCode) in filtered(sections)"
            v-bind="{...section}"
            :status="status"
            :readonly="readonly"
            :comments="comments"
            :hiddenKeys="hiddenKeys"
            :key="sectionCode"
            :pengind="pending"
            :code="sectionCode"
        />
      </VExpansionPanels>
      <div v-for="(question, code) in filtered(questions)" :class="[$style.item, errorControl(commentControl(code), question.changed)]" :key="code">
        <VRow>
          <VCol cols="12" md="4">
            <div :class="$style.title" v-html="question.label" />
            <div :class="$style.hint" v-html="question.hint" />
            <div class="mt-2">
              <VBtn v-if="(isContragent || isSpectator) && commentControl(code, null).length" :color="commentControl(code).length ? 'primary': 'light'" depressed small @click="toComments(code)">
                <template v-if="commentControl(code).length">
                  {{`Актуальные замечания (${commentControl(code).length})`}}
                </template>
                <template v-else>
                  {{`История замечаний (${commentControl(code, null).length})`}}
                </template>
              </VBtn>
              <VBtn v-if="isReviewer && waitDecision" depressed small color="light" @click="toComments(code)">
                Добавить замечание
              </VBtn>
            </div>
          </VCol>
          <VCol cols="12" md="8">
            <CounterpartyDetailValues v-bind="{...question}" :readonly="question.readonly || readonly" />
          </VCol>
        </VRow>
      </div>
    </VExpansionPanelContent>
    <VDialog v-model="dialog" max-width="900" scrollable persistent>
      <CommentCard :items="[]">
        <template v-slot:top>
          <VToolbar flat dark color="primary">
            <VToolbarTitle class="px-2">Комментарии</VToolbarTitle>
            <VSpacer/>
            <VBtn icon dark @click="dialog = false">
              <VIcon>mdi-close</VIcon>
            </VBtn>
          </VToolbar>
        </template>
        <template v-slot:bottom>
          <VCardText>
            <div v-if="isReviewer" class="flex-grow-1 px-2 py-4 border-top-1">
              <div class="mb-4">
                <VCheckbox v-for="{code, label, title} in questionsInSectionWithoutComments" v-model="selected" class="mt-0" hide-details :value="code" :label="label || title" :key="code" />
              </div>
              <CommentForm :disabled="!selected.length && pending" @submit="onSubmitComment" />
            </div>
          </VCardText>
        </template>
      </CommentCard>
    </VDialog>
  </VExpansionPanel>
</template>

<script>
import {values, keys, pick, flatten, filter, map, find, omit, reduce, get, keyBy, merge} from 'lodash-es';
import {mapActions, mapGetters} from 'vuex';
import { ROLES } from '@/store/user/enums';
import {STATUSES} from '@/store/pkopir/enums';
import CommentForm from '@/components/general/CommentForm/CommentForm';
import CommentCard from '@/components/general/CommentCard/CommentCard';
import CounterpartyDetailValues from '@/components/pkopir/CounterpartyDetailValues/CounterpartyDetailValues';
export default {
  name: 'CounterpartyDetailSection',
  components: {
    CounterpartyDetailValues,
    CommentForm,
    CommentCard,
  },
  props: {
    code: { type: String },
    mark: { type: Number },
    hint: { type: String },
    comments: { type: Object },
    label: { type: String },
    status: { type: String },
    sections: { type: Object },
    questions: { type: Object },
    readonly: { type: Boolean, default: false },
    hiddenKeys: { type: Array, default: () => []},
    pending: { type: Boolean },
  },
  data() {
    return {
      dialog: false,
      selected: [],
    };
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    id() {
      return this.$route.params.counterparty;
    },
    commentsInCurrentSection() {
      return pick(this.comments, [...keys(this.questions), ...flatten(map(this.sections, ({questions}) => keys(questions)))]);
    },
    commentsInCurrentSectionCount() {
      return reduce(this.commentsInCurrentSection, (acc, comments, question) => {
        if (this.isContragent) {
          if (find(comments, { actual: true })) {
            acc['errors'] += 1;
            return acc;
          }
        }
        if (this.isReviewer || this.isSpectator) {
          if (find(comments, { draft: true })) {
            acc['errors'] += 1
            return acc;
          }
          if (find(comments, { actual: true })) {
            acc['warnings'] += 1;
            return acc;
          }
          if (get(this.questions, [question, 'changed'], false)) {
            acc['warnings'] += 1;
            return acc;
          }
        }
        return acc;
      }, { errors: 0, warnings: 0 })
    },
    isContragent() {
      return this.hasRole(ROLES.CONTRAGENT, 'pkopir');
    },
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkopir');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkopir');
    },
    waitDecision() {
      return this.status === STATUSES.WAIT_FOR_DECISION;
    },
    questionsInSection() {
      return filter(reduce(map(this.filtered(this.sections), 'questions'), merge, this.filtered(this.questions)), ({ label }) => label);
    },
    questionsInSectionWithoutComments() {
      return values(omit(keyBy(this.questionsInSection, 'code'), keys(this.commentsInCurrentSection)));
    },
    boolQuestions() {
      return filter(this.questionsInSection, (question) => ~question.type.indexOf('BOOL'))
    },
    boolQuestionsWithoutFalseValue() {
      return filter(this.boolQuestions, (question) => filter(get(question, 'values.BOOL', []), {value: ''}).length && !get(question, 'readonly', false));
    },
    showFalseValuesButton() {
      //Показываем для контрагента, в разделах с номером > 7 где есть вопросы с булевым типом без ответа
      return this.isContragent && this.code > 'section_7' && !Object.keys(this.filtered(this.sections)).length && !!this.boolQuestionsWithoutFalseValue.length && !this.readonly;
    },
  },
  methods: {
    ...mapActions({
      fetchCommentList: 'pkopir/fetchCommentList',
      createComment: 'pkopir/createComment',
    }),
    filtered(items) {
      return omit(items, this.hiddenKeys);
    },
    commentControl(question, predicate = ({ actual, draft }) => draft || actual) {
      if (!predicate) return values(this.commentsInCurrentSection[question]);
      return filter(this.commentsInCurrentSection[question], predicate)
    },
    errorControl(comments, changed) {
      if (this.isContragent && comments.length) return 'error lighten-4';
      if ((this.isReviewer || this.isSpectator) && find(comments, { draft: true})) return 'error lighten-4';
      if ((this.isReviewer || this.isSpectator) && (changed || find(comments, { actual: true}))) return 'orange lighten-4';
    },
    toComments(question) {
      this.$router.push({ name: 'pkopir/CounterpartyDetailView', params: { question }})
    },
    onSubmitComment(message) {
      const id = this.id;
      const question = this.selected;
      this.dialog = false;
      this.createComment({ id, question, message }).then(() => {
        this.fetchCommentList({ id });
      });
    },
    setFalseValuesInSection() {
      this.boolQuestions = map(this.boolQuestions, (question) => {
        if (get(question, 'values.BOOL')) {
          question.values.BOOL = map(question.values.BOOL, (value) => {
            value.value = '0';
            return value;
          });
        }
        return question;
      });
    },
  }
}
</script>

<style module lang="scss">
.root {

}
.title {
  font-weight: bold;
  font-size: 14px;
}
.hint {
  font-style: italic;
  font-size: 13px;
}
.item {
  padding: 20px 24px;
  margin: 0 -24px;
  & + & {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
  }
}
</style>
