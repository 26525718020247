<template>
  <div :class="$style.root">
    <CounterpartyCard label="Отображение пунктов анкеты">
      <VRadioGroup v-model="simple" class="my-0" hide-details>
        <VRadio label="Все" :value="false" />
        <VRadio label="С комментариями проверяющего" :value="true" />
      </VRadioGroup>
    </CounterpartyCard>
    <VForm @submit.prevent="onSave">
      <VExpansionPanels flat multiple>
        <CounterpartyDetailSection
            v-for="(section, code) in filtered(fields)"
            v-bind="{...section}"
            :status="status"
            :readonly="readonly"
            :comments="comments"
            :hiddenKeys="hiddenKeys"
            :key="code"
            :pengind="pending"
            :code="code"
        />
      </VExpansionPanels>
    </VForm>
    <VFooter app v-if="!isSpectator">
      <div class="py-3 grow">
        <template v-if="!readonly">
          <VBtn color="primary" class="mr-2" depressed @click="onSave">Сохранить</VBtn>
          <VBtn color="secondary" class="mr-2" depressed :disabled="!diff" @click="onReset">Отменить</VBtn>
          <VBtn color="primary" class="float-right" depressed :disabled="diff || hasActualComments" @click="onAgreement">Отправить на согласование</VBtn>
        </template>
        <template v-if="waitDecision">
          <VBtn color="primary" class="mr-2" depressed :disabled="!hasDraftComments" @click="onRework">Доработка</VBtn>
          <VBtn color="success" class="mr-2" depressed :disabled="hasDraftComments" @click="onYes">Согласовать</VBtn>
          <VBtn color="error" class="float-right" depressed @click="onNo">Отказать</VBtn>
        </template>
      </div>
    </VFooter>

    <VDialog v-model="dialog" max-width="900" scrollable persistent>
      <VCard tile>
        <VToolbar flat dark color="primary">
          <VToolbarTitle class="px-2">Решение по заявке</VToolbarTitle>
          <VSpacer/>
          <VBtn icon dark @click="dialog = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VCardText class="pt-5">
          <VCardText>
            <DecisionForm :disabled="pending" @submit="onSubmitDecision" />
          </VCardText>
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import {omit, isEmpty, pickBy, keys, find, includes, forEach} from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import { STATUSES } from '@/store/pkopir/enums';
import CounterpartyCard from '@/components/pkopir/CounterpartyCard/CounterpartyCard';
import CounterpartyDetailSection from '@/components/pkopir/CounterpartyDetailSection/CounterpartyDetailSection';
import DecisionForm from '@/components/pkopir/DecisionForm/DecisionForm';
export default {
  name: 'CounterpartyDetail',
  components: {
    CounterpartyCard,
    CounterpartyDetailSection,
    DecisionForm,
  },
  props: {
    id: { type: String },
    values: { type: Object },
    status: { type: String },
    comments: { type: Object },
    pending: { type: Boolean },
  },
  data() {
    return {
      simple: false,
      fields: JSON.parse(JSON.stringify(this.values)),
      dialog: false,
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    diff() {
      return JSON.stringify(this.values) !== JSON.stringify(this.fields);
    },
    readonly() {
      return this.isSpectator || this.isReviewer || this.status === STATUSES.ON_APPROVAL;
    },
    waitDecision() {
      return this.status === STATUSES.WAIT_FOR_DECISION;
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkopir');
    },
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkopir');
    },
    hasActualComments() {
      return !isEmpty(pickBy(this.comments, (comments) => find(comments, { actual: true })));
    },
    hasDraftComments() {
      return !isEmpty(pickBy(this.comments, (comments) => find(comments, { draft: true })));
    },
    questionsWithComments() {
      return keys(pickBy(this.comments, (comments) => find(comments, ({actual, draft}) => actual || draft)));
    },
    hiddenKeys() {
      const func = (questions) => {
        const temp = [];
        forEach(questions, (question, key) => {
          // Скрыть вопросы без комментариев
          if (this.simple && !includes(this.questionsWithComments, key)) {
            temp.push(key); return;
          }
        });
        return temp;
      };
      const result = [];
      forEach(this.fields, ({ sections, questions }, key) => {
        forEach(sections, ({ questions }, key) => {
          let hide = func(questions);
          if (hide.length === keys(questions).length) result.push(key);
          result.push(...hide);
        });

        let hide = func(questions);
        result.push(...hide);
        if (hide.length === keys(questions).length && !find(keys(sections), key => !includes(result, key))) result.push(key);
      });
      return result;
    },
  },
  methods: {
    ...mapActions({
      sendDecision: 'pkopir/sendDecision',
      updateCounterparty: 'pkopir/updateCounterparty',
      agreementCounterparty: 'pkopir/agreementCounterparty',
      fetchCommentList: 'pkopir/fetchCommentList',
    }),
    filtered(items) {
      return omit(items, this.hiddenKeys);
    },
    onSave() {
      const id = this.id;
      const items = this.fields;
      this.updateCounterparty({ id, items }).then(result => {
        if (result) {
          this.fetchCommentList({ id });
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Ваша анкета сохранена, но не отправлена нажмите на кнопку в правом нижнем углу чтобы отправить на согласование',
          });
        }
      });
    },
    onAgreement() {
      const id = this.id;
      this.agreementCounterparty({ id }).then((result) => {
        if (result) {
          this.$router.push({ name: 'pkopir/CounterpartyListView' });
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Заявка отправлена на согласование',
          });
        }
      });
    },
    onRework() {
      this.decision = STATUSES.REWORK;
      this.dialog = true;
    },
    onYes() {
      this.decision = STATUSES.YES;
      this.dialog = true;
    },
    onNo() {
      this.decision = STATUSES.NO;
      this.dialog = true;
    },
    onReset: function() {
      this.fields = JSON.parse(JSON.stringify(this.values));
    },
    onSubmitDecision(comment, notation, expertMark) {
      const id = this.id;
      const decision = this.decision;
      this.sendDecision({ id, decision, comment, notation, expertMark }).then((result) => {
        if (result) {
          this.$router.push({ name: 'pkopir/CounterpartyListView' });
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Решение по заявке принято',
          });
          this.dialog = false;
        }
      });
    }
  },
  watch: {
    values: {
      handler: function() {
        this.onReset();
      }
    },
    dialog: {
      handler: function (value) {
        if (!value)
          this.decision = null;
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  $header-bg: #efefef;
  $border-color: #dee2e6;
  :global(.v-expansion-panel) {
    border: 1px solid $border-color;
    margin-bottom: -1px;
  }
}
</style>
